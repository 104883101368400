import { React, useRef } from "react";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa6";
import * as BiIcons from "react-icons/bi";
import * as MdIcons from "react-icons/md";
import { setLoggedIn } from "../utilities";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
const FooterMenu = () => {
  const handleLogout = () => {
    sessionStorage.removeItem("loginUserDetails");
    setLoggedIn(0);
  };

  return (
    <footer className="footer bg-dark text-white text-center">
      <div className="container-fluid ">
        <div className="d-flex footer-menu">
          <div className="col-2">
            <Link to="/inplay" className="footer-menu-link">
              <span className="">
                <MdIcons.MdSportsSoccer
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
              Sports
            </Link>
          </div>
          <div className="col-2">
            <Link to="/inplay" className="footer-menu-link">
              <span className="mb-1">
                {/* <img src="/Assets/images/soccer.png" alt="" /> */}
                <FaIcons.FaRegCirclePlay
                  style={{ width: "20px", height: "20px" }}
                />
              </span>
              Inplay
            </Link>
          </div>
          <div className="col-4 bigCircle ">
            <Link
              to="/home"
              className="footer-menu-link"
              style={{ marginTop: "-28px" }}
            >
              <span className="">
                <img
                  src="/Assets/images/home.png"
                  alt="home"
                  width="25"
                  height="25"
                />
              </span>
            </Link>
          </div>
          <div className="col-2">
            <Link
              to="/login"
              className="footer-menu-link"
              onClick={handleLogout}
            >
              <span className="">
                <BiIcons.BiLogOut style={{ width: "20px", height: "20px" }} />
              </span>
              Logout
            </Link>
          </div>
          <div className="col-2">
            <Link
              to="https://wa.me/+447415177972"
              target="_blank"
              className="footer-menu-link"
            >
              <span className="mb-1">
                <img
                  src="/assets/images/whatsapp_icon.png?v=12"
                  alt="support"
                  width="25"
                  height="25"
                />
              </span>
              Whatsapp
            </Link>
          </div>
        </div>
      </div>
      {/*
      <div className="App">
        <TawkMessengerReact
          propertyId="d7398fd6aec6d0205c45c6d7f1f944f2c7d30601"
          widgetId="default"
        />
      </div>
      */}
    </footer>
  );
};

export default FooterMenu;
